import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { applyTrialistExtension, sendPurchaseEmail } from "../services/APIClient";
import "./FreeTrialBanner.css";

function FreeTrialBanner({ schoolid, teacherName, freeDaysLeft, trialistDetails }) {
    const [showExtendModal, setShowExtendModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showExpiredModal, setShowExpiredModal] = useState(false); // New modal for expired trials
    const [forwardEmail, setForwardEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const handleExtendModal = () => setShowExtendModal(true);
    const handlePurchaseModal = () => setShowPurchaseModal(true);
    const handleExpiredModal = () => setShowExpiredModal(true); // Handle expired modal
    const handleModalClose = () => {
        setShowExtendModal(false);
        setShowPurchaseModal(false);
        setShowExpiredModal(false);
        setMessage(""); // Clear any messages when closing the modal
    };

    const handleApplyNow = async () => {
        setLoading(true);
        setMessage("");
        try {
            await applyTrialistExtension(schoolid);
            setMessage("Extension applied successfully!");
        } catch (error) {
            setMessage("Failed to apply for an extension. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleForwardSubmit = async () => {
        if (!/\S+@\S+\.\S+/.test(forwardEmail)) {
            setMessage("Please enter a valid email address.");
            return;
        }

        setLoading(true);
        setMessage("");
        try {
            await sendPurchaseEmail(forwardEmail, teacherName); // Call the API
            setMessage("Purchase email sent successfully!");
            setForwardEmail(""); // Clear the email field on success
        } catch (error) {
            setMessage("Failed to send purchase email. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (!trialistDetails) {
        // Show a loading state or message if trialist details have not loaded yet
        return <div>Loading...</div>;
    }

    const {
        start_date = "",
        end_date = "",
        extension_applied = false,
        completed = false,
        expired = false,
        purchased = false
    } = trialistDetails || {};

    return (
        <>
            {/* Free Trial Banner */}
            <div className="free-trial-banner">
                <div className="banner-content">
                    {purchased ? (
                        // Show Purchased Message
                        <p className="banner-message">
                            Your school has purchased ALPACA for the coming school year.
                        </p>
                    ) : expired ? (
                        // Show Expired Message
                        <p className="banner-message">
                            Your free trial has expired. Please purchase ALPACA to continue.
                        </p>
                    ) : (
                        // Show Free Trial Message
                        <p className="banner-message">
                            <strong>{freeDaysLeft !== null ? freeDaysLeft : "..."}</strong> days left in your free trial.
                            {extension_applied ? "" : " Do you need an extension?"}
                        </p>
                    )}
                </div>
                {/* Buttons for Free Trial or Expired */}
                {!purchased && !expired && (
                    <div className="banner-buttons">
                        <button
                            className="extend-btn"
                            onClick={handleExtendModal}
                            disabled={extension_applied || completed}
                        >
                            {extension_applied ? "Extension Applied" : "Extend Trial"}
                        </button>
                        <button
                            className="purchase-btn"
                            onClick={handlePurchaseModal}
                        >
                            Purchase
                        </button>
                    </div>
                )}
                {expired && !purchased && (
                    <div className="banner-buttons">
                        <button
                            className="expired-btn"
                            onClick={handleExpiredModal}
                        >
                            Learn More
                        </button>
                    </div>
                )}
            </div>

            {/* Expired Modal */}
            <Modal show={showExpiredModal} onHide={handleModalClose} className="custom-expired-modal">
                <Modal.Header closeButton className="custom-modal-header"></Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className="modal-icon-container">
                        <img
                            src="../assets/imgs/Alpaca-logo-white-outline.png"
                            alt="Alpaca Icon"
                            className="modal-icon"
                        />
                    </div>
                    <h2 className="modal-title">Your Trial Has Expired</h2>
                    <p className="modal-subtitle">
                        To continue using ALPACA, please purchase a subscription or contact us for more information.
                    </p>
                </Modal.Body>
                <Modal.Footer className="custom-modal-footer">
                    <Button
                        variant="primary"
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://26983596.hs-sites-eu1.com/post-trial-form"
                        className="purchase-btn-modal"
                    >
                        Purchase Now
                    </Button>
                    <Button
                        variant="light"
                        as="a"
                        href="mailto:info@alpaca-assessment.com"
                        className="contact-support-btn"
                    >
                        Contact Support
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Extend Trial Modal */}
            <Modal show={showExtendModal} onHide={handleModalClose} className="custom-extend-modal">
                <Modal.Header closeButton className="custom-modal-header"></Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className="modal-icon-container">
                        <img
                            src="../assets/imgs/Alpaca-logo-white-outline.png"
                            alt="Alpaca Icon"
                            className="modal-icon"
                        />
                    </div>
                    <div className="trial-end-container">
                        <span className="trial-end-badge">Trial ends on</span>
                        <span className="trial-end-date">{new Date(end_date).toLocaleDateString()}</span>
                    </div>
                    <h2 className="modal-title">Apply for a 1 Week extension</h2>
                    <p className="modal-subtitle">
                        If you have any questions, <a href="mailto:info@alpaca-assessment.com" className="contact-link">please let us know</a>.
                    </p>
                    {message && <p className="message">{message}</p>}
                </Modal.Body>
                <Modal.Footer className="custom-modal-footer">
                    <Button
                        variant="light"
                        className="apply-now-btn"
                        onClick={handleApplyNow}
                        disabled={loading || extension_applied} // Disable if already applied
                    >
                        {loading ? "Applying..." : "Apply now"}
                    </Button>
                    <Button
                        variant="primary"
                        as="a"
                        target="_blank"
                        href="https://26983596.hs-sites-eu1.com/post-trial-form"
                        className="purchase-btn-modal"
                    >
                        Purchase
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Purchase Modal */}
            <Modal show={showPurchaseModal} onHide={handleModalClose} className="custom-purchase-modal">
                <Modal.Header closeButton className="custom-modal-header"></Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className="modal-icon-container">
                        <img
                            src="../assets/imgs/Alpaca-logo-white-outline.png"
                            alt="Alpaca Icon"
                            className="modal-icon"
                        />
                    </div>
                    <div className="trial-end-container">
                        <span className="trial-end-badge">Trial ends on</span>
                        <span className="trial-end-date">{new Date(end_date).toLocaleDateString()}</span>
                    </div>
                    <h2 className="modal-title">{freeDaysLeft} days left in your free trial</h2>
                    <p className="modal-subtitle">
                        To continue enjoying all the benefits of ALPACA for your school, a subscription is required. <br />
                        Simply click the "Purchase" button below to secure access for your school.
                    </p>

                    <div className="purchase-button-container">
                        <Button
                            variant="primary"
                            as="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://26983596.hs-sites-eu1.com/post-trial-form"
                            className="purchase-btn-modal"
                            disabled={purchased}
                        >
                            {purchased ? "Purchased" : "Purchase"}
                        </Button>
                    </div>
                    <div className="forward-section">
                        <hr className="divider" />
                        <p className="forward-message">Or forward message to purchasing person</p>
                        <input
                            type="email"
                            placeholder="name@school.com"
                            value={forwardEmail}
                            onChange={(e) => setForwardEmail(e.target.value)}
                            className="form-control custom-input"
                        />
                        <Button
                            variant="primary"
                            className="forward-btn-modal"
                            onClick={handleForwardSubmit}
                            disabled={loading}
                        >
                            {loading ? "Sending..." : "Forward"}
                        </Button>
                        {message && <p className="message">{message}</p>} {/* Display success/error message */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FreeTrialBanner;
