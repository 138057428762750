export function groupAndAverageTasks(assessmentResultsByStudent) {
  return assessmentResultsByStudent.map((student, studentIndex) => {
    if (!student.tasks || student.tasks.length === 0) {
      // Return the student object with an empty tasks array
      return {
        ...student,
        tasks: [],
      };
    }

    const groupedData = {};

    student.tasks.forEach((task, taskIndex) => {
      // Extract the common identifier for grouping
      const groupName = task.name.replace(/ \([a-z]\)$/i, "");

      // Initialize group if it doesn't exist
      if (!groupedData[groupName]) {
        groupedData[groupName] = {
          name: groupName,
          count: 0,
          totalScore: 0,
          totalZScore: 0,
          totalStandardScore: 0,
          totalSTenScore: 0,
          totalGroupPercentile: 0,
          totalGlobalPercentile: 0,
          totalOrder: 0, // Accumulate orders to calculate average order
        };
      }

      // Accumulate totals for averaging
      groupedData[groupName].count++;
      groupedData[groupName].totalScore += task.score;
      groupedData[groupName].totalZScore += task.zScore;
      groupedData[groupName].totalStandardScore += task.standardScore;
      groupedData[groupName].totalSTenScore += task.sTenScore;
      groupedData[groupName].totalGroupPercentile += task.groupPercentile;
      groupedData[groupName].totalGlobalPercentile += task.globalPercentile;
      groupedData[groupName].totalOrder += task.order; // Add order for average later
    });

    // Calculate averages
    const groupedTasks = Object.values(groupedData)
      .map((group) => ({
        name: group.name,
        score: group.totalScore / group.count,
        zScore: group.totalZScore / group.count,
        standardScore: group.totalStandardScore / group.count,
        sTenScore: group.totalSTenScore / group.count,
        groupPercentile: group.totalGroupPercentile / group.count,
        globalPercentile: group.totalGlobalPercentile / group.count,
        order: group.totalOrder / group.count, // Average order
      }))
      .sort((a, b) => a.order - b.order); // Sort tasks by average order

    return {
      ...student,
      tasks: groupedTasks,
    };
  });
}

export function preprocessStudentResults(individualStudentResult) {
  return individualStudentResult.map((assessment) => {
    const groupedTasks = {};

    assessment.tasks.forEach((task) => {
      // Extract the base task name for grouping
      const baseName = task.name.replace(/ \([a-z]\)$/i, "");

      if (!groupedTasks[baseName]) {
        groupedTasks[baseName] = {
          name: baseName,
          aggregated: {
            score: 0,
            zScore: 0,
            standardScore: 0,
            sTenScore: 0,
            duration: 0, // Accumulate the total duration
            count: 0,
          },
          tasks: [], // Preserve individual tasks
        };
      }

      // Add task to the group
      groupedTasks[baseName].tasks.push(task);

      // Accumulate scores and other properties for averaging
      groupedTasks[baseName].aggregated.score += task.score;
      groupedTasks[baseName].aggregated.zScore += task.zScore;
      groupedTasks[baseName].aggregated.standardScore += task.standardScore;
      groupedTasks[baseName].aggregated.sTenScore += task.sTenScore;
      groupedTasks[baseName].aggregated.duration += task.duration; // Keep summing durations
      groupedTasks[baseName].aggregated.count += 1;
    });

    // Calculate averages for all aggregated data except duration
    Object.values(groupedTasks).forEach((group) => {
      const { aggregated } = group;
      aggregated.score /= aggregated.count;
      aggregated.zScore /= aggregated.count;
      aggregated.standardScore /= aggregated.count;
      aggregated.sTenScore /= aggregated.count;
      // aggregated.duration remains as the sum of task durations
    });

    return {
      ...assessment,
      groupedTasks: Object.values(groupedTasks),
    };
  });
}
