import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import {
  getClass,
  getStudents,
  getAllStudentsResultsPerAssessment,
  updateUserActivity
} from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import StudentsResultsOverview from "../components/StudentsResultsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

function StudentsResults(props) {
  const location = useLocation();

  let name = "Results";
  console.log(location, "LOCATION");

  // Handle undefined state
  let classDetails = location.state?.classDetails?.classDetails || {};
  let gradeLevel = location.state?.gradeLevel?.gradeLevel || "";
  let classAssessments =
    location.state?.classAssessments?.classAssessments || [];
  // let teacherid = classDetails.teacherId || "";
  let teachers = location.state?.teachers?.teachers || [];
  let assessment = location.state?.assessment?.assessment || {};
  let assessmentInstanceID =
    location.state?.assessmentInstanceID?.assessmentInstanceID || "";
  let assessmentInstances =
    location.state?.assessment?.assessment.instances || {};

  const [studentsResponse, setStudentsResponse] = useState("");
  const [error, setError] = useState(null);
  const [studentIds, setStudentIds] = useState([]);
  const [studentResults, setStudentResults] = useState([]);

  console.log(classAssessments, "STATUS");

  let schoolid = location.state?.schoolid?.schoolid;
  let teacherid = location.state?.teacherid?.teacherid;
  let classid = classDetails.id;

  useEffect(() => {
    getStudents(classid).then((response) =>
      setStudentsResponse(response.students)
    );
  }, [classid]);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    // Extract student IDs whenever studentsResponse changes
    if (Array.isArray(studentsResponse)) {
      const ids = studentsResponse.map((student) => student.id);
      setStudentIds(ids);
    }
  }, [studentsResponse]);

  useEffect(() => {
    // Fetch results when studentIds and assessmentInstanceID are available

    getAllStudentsResultsPerAssessment(studentIds, assessmentInstances)
      .then((data) => {
        setStudentResults(data);
        console.log("fired");
      })
      .catch((error) => {
        console.error("Error fetching results:", error);
        setError("Error fetching results");
      });
  }, [studentIds, assessmentInstances]);

  useEffect(() => {
    // Fetch results when studentIds and assessmentInstanceID are available

    updateUserActivity(teacherid, "results-viewed")
  }, [studentIds, assessmentInstances]);
  

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <StudentsResultsOverview
            classDetails={classDetails}
            classAssessments={classAssessments}
            // teachers={location.state.teachers}
            // sharedWith={sharedWith}
            students={studentsResponse}
            gradeLevel={gradeLevel}
            teachers={teachers}
            assessment={assessment}
            assessmentInstanceID={assessmentInstanceID}
            schoolid={schoolid}
            teacherid={teacherid}
            studentResults={studentResults}
            // name={location.state.name.name}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default StudentsResults;
