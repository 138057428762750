import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { QRCodeSVG } from "qrcode.react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./QRCodeGenerator.css";

function QRCodeGenerator(props) {
  // console.log(props)
  let student = props.student;
  let studentName = student.name;
  let studentPassword = student.password;
  let studentID = student.id;

  let qrIndex = props.index;

  let assessmentURL = process.env.REACT_APP_QR_CODE_BASE_URL + "/?code=";
  if (window.location.hostname.includes("localhost")) {
    // for testing
    assessmentURL = "http://localhost:3002/?code=";
  } else if (window.location.hostname.includes("staging")) {
    // for staging
    assessmentURL = "https://staging.learner.alpaca-assessment.com/?code=";
  } else {
    // for production
    assessmentURL = "https://learner.alpaca-assessment.com/?code=";
  }

  // Creates QR logins that are readable by Student App
  let removeHyphens = studentID.replaceAll("-", "");
  let first32 = removeHyphens.substring(0, 32);
  let newQrCode = first32.concat(studentPassword);
  let qRWithUrl = assessmentURL.concat(newQrCode);

  // console.log(studentName);
  // // console.log(newQrCode)
  // console.log(qRWithUrl);

  // const [qrValue] = useState(studentName);

  // const downloadQRCode = () => {
  // 	// Generate download with use canvas and stream
  // 	const canvas = document.getElementById("qr-gen");
  // 	const pngUrl = canvas
  // 		.toDataURL("image/png")
  // 		.replace("image/png", "image/octet-stream");
  // 	let downloadLink = document.createElement("a");
  // 	downloadLink.href = pngUrl;
  // 	downloadLink.download = `${qrValue}.png`;
  // 	document.body.appendChild(downloadLink);
  // 	downloadLink.click();
  // 	document.body.removeChild(downloadLink);
  // };

  return (
    <>
      <Col className="col-4 qr-code-container" data-qrid={qrIndex}>
        <div>
          <h2 className="qr-student-name-container">{studentName}</h2>
          <QRCodeSVG
            id="qr-gen"
            value={qRWithUrl}
            size={230}
            level={"H"}
            includeMargin={true}
            imageSettings={{
              src: "/assets/imgs/Alpaca-logo-white-outline.png",
              height: 60,
              width: 48,
            }}
          />
        </div>

        {/* <div className=''></div> */}
        {/* <p>
				Click for{" "}
				<button type="button" onClick={downloadQRCode}>
					Download
				</button>
			</p> */}
      </Col>
    </>
    // <div className="pagebreak"></div>
  );
}

export default QRCodeGenerator;
