import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"; // Import toast and ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

import "./ShareClassOverview.css";
import SimpleNavigationButton from "./SimpleNavigationButton";
import AdditionalColleaguesFunction from "./AdditionalColleaguesFunction";
import SubmitInviteButton from "./SubmitInviteButton";
import AlreadyInvitedModal from "./AlreadyInvitedModal";
import {
  getClasses,
  addAdditionalColleagues,
  checkColleagueExists,
  updateUserActivity,
} from "../services/APIClient";

function InviteColleaguesOverview(props) {
  const [colleagues, setColleagues] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [colleagueData, setColleagueData] = useState([]);
  const [alreadyInvitedColleagues, setAlreadyInvitedColleagues] = useState([]);
  const [showAlreadyInvitedModal, setShowAlreadyInvitedModal] = useState(false);
  const [hasInvalidEmails, setHasInvalidEmails] = useState(false);

  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let countryId = props.countryId;
  let schoolid = props.schoolid;
  let rollNumber = props.rollNumber;
  let classDetails = props.classDetails;
  let teacherId = props.teacherId;
  let teachers = props.teachers;
  let gradeLevel = props.gradeLevel;
  let teacherName = props.name;
  let fromCreateClass = props.fromCreateClass;

  console.log(teachers, "PROPS");

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const classesData = await getClasses();
        setClasses(classesData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchClasses();
  }, []);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleCloseAlreadyInvitedModal = () => {
    setShowAlreadyInvitedModal(false);
    navigate("/mycolleagues", {
      state: {
        classDetails: classDetails,
        teachers: teachers,
        name: teacherName,
        gradeLevel: gradeLevel,
        countryId: countryId,
      },
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    console.log(colleagues, "Colleagues");
  }, [colleagues]);

  const handleSubmit = async () => {
    const filteredColleagueData = colleagueData.filter(
      (colleague) =>
        colleague.firstName.trim() &&
        colleague.lastName.trim() &&
        colleague.email.trim()
    );

    if (filteredColleagueData.length > 0) {
      try {
        const existingColleagues = await Promise.all(
          filteredColleagueData.map(async (colleague) => {
            const exists = await checkColleagueExists(colleague.email);
            return exists ? colleague : null;
          })
        );

        const newColleagues = filteredColleagueData.filter(
          (colleague) => !existingColleagues.includes(colleague)
        );

        if (newColleagues.length > 0) {
          const payload = {
            schoolid,
            rollNumber,
            newColleagues,
            teacherName,
            countryId,
          };
          console.log(
            "Payload being sent to addAdditionalColleagues:",
            payload
          );

          // Use .then() to handle navigation after the successful API call
          await addAdditionalColleagues(
            schoolid,
            rollNumber,
            newColleagues,
            teacherName,
            countryId
          )
            .then(() => {
              // Show success toast
              toast.success("Colleagues added successfully!");

              updateUserActivity(teacherId, "colleagues-added");
              setTimeout(() => {
                navigate("/mycolleagues");
              }, 4000);
            })
            .catch((error) => {
              console.error("Failed to add colleagues", error);
              toast.error("Failed to add colleagues. Please try again.");
            });
        }

        const alreadyInvited = existingColleagues.filter(Boolean);
        if (alreadyInvited.length > 0) {
          setAlreadyInvitedColleagues(alreadyInvited);
          console.log("Setting showAlreadyInvitedModal to true");
          setShowAlreadyInvitedModal(true);
        }

        handleClose();
      } catch (error) {
        console.error("Failed to add colleagues", error);
        toast.error("Failed to add colleagues. Please try again.");
      }
    } else {
      console.log("No valid colleague data to submit.");
    }
  };

  const validColleagues = colleagueData.filter(
    (colleague) =>
      colleague.firstName.trim() &&
      colleague.lastName.trim() &&
      colleague.email.trim()
  );

  return (
    <div className="class-overview-container invite-colleagues-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col xs={8}>
            <h2 className="page-description-header">Add Colleagues</h2>
            <p>You can add colleagues from your school to the ALPACA system</p>
            <p>
              Simply add their school emails, names, and roles below and click{" "}
              <strong>Add</strong>!
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <AdditionalColleaguesFunction
              colleagues={colleagues}
              setColleagues={setColleagueData}
              classes={classes}
              loading={loading}
              error={error}
              setHasInvalidEmails={setHasInvalidEmails}
              inputClassName="input-field" // Pass the input field class
              fromCreateClass={fromCreateClass}
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col className="shareclass-button-placer">
            <SimpleNavigationButton
              path="/mycolleagues"
              label="Back"
              colour="white"
            />
            <button
              className="green-button"
              onClick={handleShow}
              disabled={hasInvalidEmails}
            >
              Add
            </button>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-centered">
            Review Colleagues
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validColleagues.length > 0 ? (
            <>
              <p>Do these names and emails look right?</p>
              <p>
                Press <strong>Add Colleagues</strong> when happy.
              </p>
              <p>
                If you want to go back and make changes, press{" "}
                <strong>Cancel</strong> and you can continue where you left off.
              </p>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Shared Class</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validColleagues.map((colleague, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{colleague.firstName}</td>
                        <td>{colleague.lastName}</td>
                        <td>{colleague.email}</td>
                        <td>
                          {classes.find(
                            (cls) => cls.id === colleague.sharedClassId
                          )?.name || "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <p>
              There are no colleagues to send. Please add some colleagues and
              try again.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {validColleagues.length > 0 && (
            <SubmitInviteButton onClick={handleSubmit} />
          )}
        </Modal.Footer>
      </Modal>

      <AlreadyInvitedModal
        show={showAlreadyInvitedModal}
        handleClose={handleCloseAlreadyInvitedModal}
        alreadyInvitedColleagues={alreadyInvitedColleagues}
        teachers={teachers}
        classes={classes}
      />

      {/* Toast Notification */}
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default InviteColleaguesOverview;
