import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import InviteColleaguesOverview from "../components/InviteColleaguesOverview";
import OrientationPrompt from "../components/OrientationPrompt";

function InviteColleagues(props) {
  const location = useLocation();

  console.log(location.state, "location");

  let name = location.state?.name.name;
  let rollNumber = location.state.rollNumber.rollNumber;
  let teacherEmail = location.state.teacherEmail.teacherEmail;
  let schoolid = location.state.schoolid.schoolid;
  let countryId = location.state.countryId.countryId;
  let fromCreateClass = location.state?.fromCreateClass;
  let teacherid = location.state.teacherId.teacherId;

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  console.log(location.state.teachers, "TEACHERS");

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  // console.log(rollNumber)
  // console.log(teacherEmail)

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        isLoggedIn={true}
        cloud={true}
        name={name}
        // teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <InviteColleaguesOverview
            classDetails={location.state.classDetails}
            teachers={location.state.teachers}
            sharedWith={location.state?.sharedWith}
            // classDetails={specificClass}
            gradeLevel={location.state.gradeLevel?.gradeLevel}
            name={location.state.name.name}
            rollNumber={rollNumber}
            teacherEmail={teacherEmail}
            schoolid={schoolid}
            countryId={countryId}
            fromCreateClass={fromCreateClass}
            teacherId={teacherid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default InviteColleagues;
