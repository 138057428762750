import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactToPrint from "react-to-print";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const recommendationsStyle = {
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  fontSize: "16px",
  maxWidth: "600px",
  textAlign: "center",
  margin: "20px auto",
  lineHeight: "1.5",
};

class TaskScoresComparisonChart extends React.Component {
  render() {
    const { assessmentResultsByStudent, fromSencoResults, className } =
      this.props;

    // Log the initial props
    console.log("Props received:", {
      assessmentResultsByStudent,
      fromSencoResults,
      className,
    });

    const tasks = [
      ...new Set(
        assessmentResultsByStudent.flatMap((student) =>
          student.tasks.map((task) => task.name)
        )
      ),
    ];
    console.log("Extracted tasks:", tasks);

    // Calculate class average standard scores for each task
    const classAverageStandardScores = tasks.reduce((acc, task) => {
      const taskScores = assessmentResultsByStudent
        .flatMap((student) => student.tasks)
        .filter((t) => t.name === task);

      const averageStandardScore =
        taskScores.reduce((sum, t) => sum + t.standardScore, 0) /
        taskScores.length;

      acc[task] = averageStandardScore.toFixed(2);
      return acc;
    }, {});
    console.log("Class average standard scores:", classAverageStandardScores);

    const nationalAverageStandardScores = tasks.reduce((acc, task) => {
      acc[task] = 100; // Fixed value for demonstration
      return acc;
    }, {});
    console.log(
      "National average standard scores (mocked):",
      nationalAverageStandardScores
    );

    const data = {
      labels: tasks,
      datasets: [
        {
          label: "Class Average Standardized Score",
          data: tasks.map((task) =>
            parseFloat(classAverageStandardScores[task] || "0")
          ),
          backgroundColor: "rgba(144, 238, 144, 0.6)", // Light green
          borderColor: "rgba(144, 238, 144, 1)",
          borderWidth: 1,
        },
        {
          label: "National Average Standardized Score",
          data: tasks.map((task) =>
            parseFloat(nationalAverageStandardScores[task] || "0")
          ),
          backgroundColor: "rgba(255, 165, 0, 0.6)", // Light orange
          borderColor: "rgba(255, 165, 0, 1)",
          borderWidth: 1,
        },
      ],
    };
    console.log("Chart data:", data);

    const options = {
      responsive: true,
      scales: {
        x: {
          title: {
            display: true,
            text: "Tasks",
            font: {
              size: 16,
            },
          },
          ticks: {
            font: {
              size: 14,
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Standardized Scores",
            font: {
              size: 16,
            },
          },
          ticks: {
            callback: (value) => `${value.toFixed(2)}`,
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
            },
          },
        },
      },
    };

    return (
      <div>
        {fromSencoResults && (
          <div>
            <ReactToPrint
              trigger={() => (
                <button
                  className="blue-button"
                  style={{ marginBottom: "10px" }}
                >
                  Print Graph
                </button>
              )}
              content={() => this.componentRef}
            />
          </div>
        )}
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Class Average vs. National Average Standardized Score
        </h2>
        <div ref={(el) => (this.componentRef = el)}>
          {fromSencoResults && (
            <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
              {className}
            </h3>
          )}
          <Bar data={data} options={options} />
          <div style={recommendationsStyle}>
            <h3 style={{ textAlign: "center" }}>
              Class Average vs National Average Standardized Score
            </h3>
            <p>
              This graph compares the class's performance percentiles with
              Standardized scores as percentiles. Percentiles show how the
              class’s performance compares to others, like a ranking, to see if
              they are above or below national standards. Green bars represent
              the class’s percentiles, while orange bars show national
              benchmarks (which will always be 100 as an average) The horizontal
              base of the graph lists the screened areas, and its vertical left
              side shows percentiles. This comparison reveals how the class’s
              performance aligns with national standards. 0-55 = slightly below
              average 55-70 = Below Average 70-85 = Slightly Below Average 85 –
              115 = Within the Average Range 115 – 130 = Above the Average Range
              130 – 145 = Significantly Above Average 145 and above =
              Significantly above average
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskScoresComparisonChart;
