import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TaskScoreGraph from "./TaskScoreGraph";
import GradeCard from "./GradeCard";
import StudentsResultsTable from "./StudentsResultsTable";
import ReturnToResults from "./ReturnToResults";
import RecommendationsButton from "./RecommendationsButton";
import ScoreToggleButton from "./ScoreToggleButton";
import AssessmentDropdown from "./AssessmentDropdown";
import PrintClassResults from "./PrintClassResults";
import ExportClassResults from "./ExportClassResults";
import ScoreInterpretationTable from "./ScoreInterpretationTable";
import PieThresholdGraph from "./PieThresholdGraph";
import ClassroomAnalysis from "./ClassroomAnalysis";
import StudentScoresBarChart from "./StudentScoresBarChart";
import StudentTaskScoresChart from "./StudentTaskScoresChart";
import TaskScoresComparisonChart from "./TaskScoresComparisonChart";
import StudentPerformancePieChart from "./StudentPerformancePieChart";
import NormativeCurveChart from "./NormativeCurveGraph";
import IndividualChild from "./IndividualChild";
import StandardScoresGraph from "./StandardScoresGraph";
import ScoreStatements from "./ScoreStatements";
import CandyBearAnalysisGraph from "./CandyBearAnalysisGraph";
import StudentPermanceExplanationTable from "./StudentPerformanceExplanationTable";
import StudentPerformancePrintViewAlphabetical from "./StudentPerformancePrintViewAlphabetical";
import StudentPerformancePrintViewScore from "./StudentPerformancePrintViewScore";

import {
  getResultsByStudent,
  getTask,
  getIndividualStudentResults,
  assignAssessment,
  getClassesBySchoolId,
  createScoreHeatMap,
} from "../services/APIClient";

import {groupAndAverageTasks} from "../services/GroupAndAverageTasks";

function StudentsResultsOverview(props) {
  const {
    classDetails,
    classAssessments,
    gradeLevel,
    teachers,
    assessment,
    schoolid,
    teacherid,
    studentResults,
    students,
  } = props;
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedChildID, setSelectedChildID] = useState("");
  const [individualStudentResult, setIndividualStudentResult] = useState("");
  const [toggleScoreType, setToggleScoreType] = useState("");
  const [sortCriteria, setSortCriteria] = useState({
    field: null,
    order: null,
  });
  const [showAtRiskOnly, setShowAtRiskOnly] = useState(false);
  const [showAnalysisPrintOptions, setShowAnalysisPrintOptions] =
    useState(false);
  const [showInsightsPrintOptions, setShowInsightsPrintOptions] =
    useState(false);
  const [showChildPrintOptions, setShowChildPrintOptions] = useState(false);
  const [showPrintView, setShowPrintView] = useState(false); // State for toggle visibility
  const [showStudentPerformancePrintView, setShowStudentPerformancePrintView] =
    useState(false); // New state for print view visibility

  const [
    showStudentPerformanceScorePrintView,
    setShowStudentPerformanceScorePrintView,
  ] = useState(false);

  const [selectedView, setSelectedView] = useState("");

  const classAnalysisRef = useRef();
  const taskScoeGraphRef = useRef();
  const pieThresholdWithAnalysisRef = useRef();
  const classInsightsRef = useRef();
  const studentScoresChartRef = useRef();
  const studentPerformanceChartRef = useRef();
  const taskScoreComparisonRef = useRef();
  const individualChildRef = useRef();
  const candyBearRef = useRef();
  const standardScoreGraphRef = useRef();
  const studentPerformancePieRef = useRef();
  const performanceTableRef = useRef();

  const navigate = useNavigate();

  const groupedResults = useMemo(
    () => groupAndAverageTasks(assessmentResultsByStudent),
    [assessmentResultsByStudent]
  );

  const studentReadingScores = useMemo(() => {
    return assessmentResultsByStudent.map((student) => {
      const totalScore = student.tasks.reduce((sum, task) => sum + task.score, 0);
      const readingScore =
        student.tasks.length > 0
          ? Math.round((totalScore / student.tasks.length) * 100)
          : 0;

      return {
        id: student.student.id,
        readingScore,
      };
    });
  }, [assessmentResultsByStudent]);

  let fullTaskNameArray = [];
  function taskNameRetriever() {
    for (let i = 0; i < groupedResults.length; i++) {
      let student = groupedResults[i];
      let studentTasks = student?.tasks;

      if (studentTasks) {
        studentTasks.sort(function (a, b) {
          return a.order - b.order;
        });
      }

      for (let j = 0; j < studentTasks?.length; j++) {
        let taskNames = studentTasks[j].name;
        fullTaskNameArray.push(taskNames);
      }
    }
  }
  taskNameRetriever();

  let taskNameArray = [...new Set(fullTaskNameArray)];

  useEffect(() => {
    if (assessment && assessment.instances) {
      getResultsByStudent(assessment.instances).then((response) => {
        setAssessmentResultsByStudent(response);
      });
    }
  }, [assessment]);

  console.log(assessmentResultsByStudent, "ARBS");
  console.log(classAssessments, "Class assessments");
  console.log(individualStudentResult, "individualStudentResult");

  useEffect(() => {
    getIndividualStudentResults(selectedChildID)
      .then((response) => {
        setIndividualStudentResult(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedChildID]);

  // Prepare data for TaskScoreGraph
  const tasksData = useMemo(() => {
    const taskScores = {};

    groupAndAverageTasks(assessmentResultsByStudent).forEach((student) => {
      const studentTasks = student.tasks || [];
      studentTasks.forEach((task) => {
        const taskName = task.name;
        const score = toggleScoreType ? task.groupPercentile : task.score * 100;

        if (!taskScores[taskName]) {
          taskScores[taskName] = [];
        }
        taskScores[taskName].push(score);
      });
    });

    // Calculate average scores
    Object.keys(taskScores).forEach((taskName) => {
      const scores = taskScores[taskName];
      const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
      taskScores[taskName] = averageScore.toFixed(2);
    });

    return taskScores;
  }, [assessmentResultsByStudent, toggleScoreType]);

  const thresholds = {
    Rhyming: { task: 20, student: 25 },
    "Initial Phoneme": { task: 20, student: 20 },
    "Phoneme Blending": { task: 20, student: 20 },
    "Letter Name": { task: 20, student: 15 },
    Deletion: { task: 20, student: 20 },
    "Letter Sound": { task: 20, student: 20 },
    "Word Recognition": { task: 10, student: 15 },
    "Non-Word Recognition": { task: 10, student: 15 },
  };

  const toAllStudentResults = () => {
    navigate("/allstudents/results", {
      state: {
        studentResults: { studentResults },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        gradeLevel: { gradeLevel },
        teachers: { teachers },
        assessment: { assessment },
        taskNameArray: { taskNameArray },
        assessmentResultsByStudent: { groupedResults },
        toggleScoreType: { toggleScoreType },
        schoolid: { schoolid },
        teacherid: { teacherid },
        students: { students },
      },
    });
  };

  const toReportCards = () => {
    navigate("/allstudents/reportcards", {
      state: {
        studentResults: { studentResults },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        gradeLevel: { gradeLevel },
        teachers: { teachers },
        assessment: { assessment },
        taskNameArray: { taskNameArray },
        assessmentResultsByStudent: { groupedResults },
        toggleScoreType: { toggleScoreType },
        schoolid: { schoolid },
        teacherid: { teacherid },
        students: { students },
      },
    });
  };

  const handleTabSelect = (tabTitle) => {
    setSelectedView(tabTitle); // Update the state with the selected tab title

    createScoreHeatMap({
      teacherId: teacherid, // Pass teacher ID from props
      selectedView: tabTitle, // Use the selected tab title as the view
    }).catch((error) => {
      console.error("Failed to create ScoreHeatMap:", error);
    });
  };

  console.log(groupAndAverageTasks(assessmentResultsByStudent))

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <Row>
              <Col className="col-6">
                <h2 className="page-description-header">{classDetails.name}</h2>
              </Col>
              <Col className="col-4">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={classDetails.grade_level1}
                  gradeLevel2={classDetails.grade_level2}
                  trialClass={classDetails.trial_class}
                />
              </Col>
            </Row>
            <p>
              View the Results for a class, click on a student to see a more
              detailed breakdown.
            </p>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-3"></Col>
              <Col className="col-3">
                <ReturnToResults
                  classDetails={classDetails}
                  teachers={teachers}
                  classAssessments={classAssessments}
                  assessment={assessment}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-4">
                <RecommendationsButton onResultsPage={true} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Tabs
          id="results-tabs"
          defaultActiveKey="My Class"
          // activeKey={selectedView}
          onSelect={(tabTitle) => handleTabSelect(tabTitle)} // Calls handleTabSelect with the tab's eventKey
          className="mb-3"
        >
          <Tab eventKey="My Class" title="My Class">
            <Row>
              <Col className="col-5">
                <h4 className="page-description-header">{assessment.name}</h4>
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-6">
                <div className="d-flex align-items-center">
                  <h4 className="page-description-header">Select Assessment</h4>
                  <AssessmentDropdown
                    classAssessments={classAssessments}
                    classDetails={classDetails}
                    teachers={teachers}
                    assessmentName={assessment.name}
                    assessment={assessment}
                    teacherid={teacherid}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-5">
                <ScoreInterpretationTable />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="col-3">
                <PrintClassResults
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                  toggleScoreType={toggleScoreType}
                  sortCriteria={sortCriteria}
                  showAtRiskOnly={showAtRiskOnly}
                  readingScores={studentReadingScores}
                />
              </Col>
              <Col className="col-3">
                {/* <ExportClassResults
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  toggleScoreType={toggleScoreType}
                /> */}
                <button className="blue-button" onClick={toAllStudentResults}>
                  View All - Detailed
                </button>
              </Col>
              <Col className="col-3">
                <button className="blue-button" onClick={toReportCards}>
                  View All - Report Cards
                </button>
              </Col>
              <Col className="col-6">
                <ScoreToggleButton setScoreType={setToggleScoreType} />
              </Col>
            </Row>
            <Row>
              <Col>
                <StudentsResultsTable
                  classDetails={classDetails}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                  readingScores={studentReadingScores}
                  toggleScoreType={toggleScoreType}
                  isPrintOut={false}
                  sortCriteria={sortCriteria}
                  showAtRiskOnly={showAtRiskOnly}
                  setSortCriteria={setSortCriteria}
                  setShowAtRiskOnly={setShowAtRiskOnly}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              </Col>
            </Row>
            <Row>
              <Col className="results-buttons"></Col>
            </Row>
          </Tab>
          <Tab eventKey="Class Analysis" title="Class Analysis">
            <Row>
              <Col>
                <button
                  className="blue-button"
                  onClick={() =>
                    setShowAnalysisPrintOptions(!showAnalysisPrintOptions)
                  }
                >
                  {showAnalysisPrintOptions
                    ? "Hide Print Options"
                    : "View Print Options"}
                </button>

                {showAnalysisPrintOptions && (
                  <div className="print-options">
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Full Class Analysis
                        </button>
                      )}
                      content={() => classAnalysisRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Task Score Graph
                        </button>
                      )}
                      content={() => taskScoeGraphRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Threshold Graph with Analysis
                        </button>
                      )}
                      content={() => pieThresholdWithAnalysisRef.current}
                    />
                  </div>
                )}
                <hr />
                <div ref={classAnalysisRef}>
                  <div ref={taskScoeGraphRef}>
                    <TaskScoreGraph tasksData={tasksData} />
                    <hr />
                  </div>
                  <div ref={pieThresholdWithAnalysisRef}>
                    <PieThresholdGraph
                      assessmentResults={groupAndAverageTasks(assessmentResultsByStudent)}
                      readingScores={studentReadingScores}
                      thresholds={thresholds}
                      fromSencoResults={false}
                    />
                    <hr />
                    <ClassroomAnalysis
                      classid={classDetails.id}
                      assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                      thresholds={thresholds}
                      readingScores={studentReadingScores}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="Class Insights" title="Class Insights">
            <Row>
              <Col>
                <button
                  className="blue-button"
                  onClick={() =>
                    setShowInsightsPrintOptions(!showInsightsPrintOptions)
                  }
                >
                  {showInsightsPrintOptions
                    ? "Hide Print Options"
                    : "View Print Options"}
                </button>
                {showInsightsPrintOptions && (
                  <div className="print-options">
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Class Insights
                        </button>
                      )}
                      content={() => classInsightsRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Student Results by Subtest
                        </button>
                      )}
                      content={() => studentScoresChartRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Student Performance in 5 Subtests
                        </button>
                      )}
                      content={() => studentPerformanceChartRef.current}
                    />
                    <ReactToPrint
                      trigger={() => (
                        <button className="blue-button">
                          Print Task Score Comparison
                        </button>
                      )}
                      content={() => taskScoreComparisonRef.current}
                    />
                  </div>
                )}
                <div ref={classInsightsRef}>
                  <div ref={studentScoresChartRef}>
                    <StudentScoresBarChart
                      assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                      readingScores={studentReadingScores}
                    />
                  </div>
                  <hr />
                  <div ref={studentPerformanceChartRef}>
                    <StudentTaskScoresChart
                      assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                      readingScores={studentReadingScores}
                    />
                  </div>
                  <hr />
                  <div ref={taskScoreComparisonRef}>
                    <TaskScoresComparisonChart
                      assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                      readingScores={studentReadingScores}
                      fromSencoResults={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="Planning SET" title="Planning SET">
            <Row>
              <Col className="blue-button-container">
                <button
                  className="blue-button"
                  onClick={() => {
                    setShowPrintView(!showPrintView);
                    setShowStudentPerformancePrintView(!showPrintView); // Show print view when toggling
                  }}
                >
                  {showPrintView
                    ? "Hide Alphabetical Print View"
                    : "View Alphabetical Print View"}
                </button>
              </Col>
            </Row>
            {showStudentPerformancePrintView && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Alphabetical View
                    </button>
                  )}
                  content={() => studentPerformancePieRef.current}
                />
                <div ref={studentPerformancePieRef}>
                  <StudentPerformancePrintViewAlphabetical
                    assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                    readingScores={studentReadingScores}
                    thresholds={thresholds}
                  />
                </div>
              </>
            )}
            {!showPrintView && (
              <StudentPerformancePieChart
                assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
                thresholds={thresholds}
                classDetails={classDetails}
                classAssessments={classAssessments}
                gradeLevel={gradeLevel}
                teachers={teachers}
                assessment={assessment}
                toggleScoreType={toggleScoreType}
                isPrintOut={false}
                sortCriteria={sortCriteria}
                showAtRiskOnly={showAtRiskOnly}
                setSortCriteria={setSortCriteria}
                setShowAtRiskOnly={setShowAtRiskOnly}
                schoolid={schoolid}
                teacherid={teacherid}
                readingScores={studentReadingScores}
              />
            )}
            <hr></hr>
            <StudentPermanceExplanationTable ref={performanceTableRef} />
          </Tab>
          <Tab eventKey="Individual Child" title="Individual Child">
            <button
              className="blue-button"
              onClick={() => setShowChildPrintOptions(!showChildPrintOptions)}
            >
              {showChildPrintOptions
                ? "Hide Print Options"
                : "View Print Options"}
            </button>
            {showChildPrintOptions && (
              <div className="print-options">
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Individual Child
                    </button>
                  )}
                  content={() => individualChildRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Standard Scores Graph
                    </button>
                  )}
                  content={() => standardScoreGraphRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Candy Bear Analysis
                    </button>
                  )}
                  content={() => candyBearRef.current}
                />
              </div>
            )}
            <IndividualChild
              assessmentResultsByStudent={groupAndAverageTasks(assessmentResultsByStudent)}
              readingScores={studentReadingScores}
              setSelectedChild={setSelectedChild}
              selectedChild={selectedChild}
            />
            <hr></hr>
            <div ref={individualChildRef}>
              <div ref={standardScoreGraphRef}>
                <StandardScoresGraph selectedChild={selectedChild} />
                <ScoreStatements selectedChild={selectedChild} />
              </div>
              <hr></hr>
              <div ref={candyBearRef}>
                <CandyBearAnalysisGraph
                  selectedChild={selectedChild}
                  setSelectedChildID={setSelectedChildID}
                  individualStudentResult={individualStudentResult}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default StudentsResultsOverview;
