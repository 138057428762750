import React, { useEffect, useState } from "react";
import HeadingBanner from "../components/HeadingBanner";
import AssessmentClassCard from "../components/AssessmentClassCard";
import LeftPanel from "../components/LeftPanel";
import AlpacaInfoGesture from "../components/AlpacaInfoGesture";
import ClassFilter from "../components/ClassFilter";
import { useNavigate } from "react-router-dom";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  getTrialistsBySchool,
  updateTrialistStartDate,
} from "../services/APIClient";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "../components/AssessmentClassCard.css";
import OrientationPrompt from "../components/OrientationPrompt";
import FreeTrialBanner from "../components/FreeTrialBanner";

function AssessmentsDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teacherRoles, setTeacherRoles] = useState(null);
  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showStartTrialModal, setShowStartTrialModal] = useState(false);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const navigate = useNavigate();

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherRoles(response.data.stakeholder.additionalRoles);
    });
  }, []);

  useEffect(() => {
    async function fetchSchoolData() {
      try {
        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);

        let classesResponse = await getClasses();

        // Remove duplicate classes based on their id
        classesResponse = classesResponse.filter(
          (classItem, index, self) =>
            index === self.findIndex((t) => t.id === classItem.id)
        );

        // Sort classes by the date_created field in descending order
        classesResponse.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setClasses(classesResponse);

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;
          setRollNumber(rollNumber);

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions([gradesResponse]);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    }

    if (schoolid) {
      fetchSchoolData().finally(() => {
        setLoading(false);
      });
    }
  }, [schoolid]);

  useEffect(() => {
    if (name) {
      const nameArray = name.split(" ");
      setFirstname(nameArray[0]);
    }
  }, [name, schoolid]);

  useEffect(() => {
    setSpeechText(
      `Whoa slow down there ${firstname}. We need to create a class first. Click into "Admin" on the Left to get this started`
    );
  }, [firstname]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid);
        if (response.length > 0) {
          const details = response[0];
          setTrialistDetails(details);

          // Check if the trial has not started
          if (!details.start_selected || new Date(details.start_date) > new Date()) {
            setShowStartTrialModal(true);
          }

          // Calculate freeDaysLeft
          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          const timeDiff = endDate - currentDate;
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0);
        }
      } catch (error) {
        console.error("Failed to fetch trialist details:", error);
      }
    };
    fetchTrialistDetails();
  }, [schoolid]);

  const handleStartTrial = async () => {
    try {
      await updateTrialistStartDate(schoolid, selectedDate.toISOString());
      setShowStartTrialModal(false);
      alert("Trial start date has been updated. Refresh to see changes.");
    } catch (error) {
      console.error("Failed to set trial start date:", error);
    }
  };

  const { expired = false, purchased = false, start_selected = true, start_date } =
    trialistDetails || {};

  const currentDate = new Date();
  const trialStartDate = start_date ? new Date(start_date) : null;
  const isTrialActive =
    start_selected && trialStartDate && trialStartDate <= currentDate;

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`${name}'s Screener`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />

        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                <Row className="row-position">
                  {!purchased ? (
                    <FreeTrialBanner
                      schoolid={schoolid}
                      teacherName={name}
                      freeDaysLeft={freeDaysLeft}
                      trialistDetails={trialistDetails}
                    />
                  ) : (
                    null
                  )}
                  <Col xs={8}>
                    <ClassFilter
                      classes={classes}
                      setFilteredClasses={setFilteredClasses}
                      gradeLevelOptions={gradeLevelOptions}
                      teacherRoles={teacherRoles}
                    />
                  </Col>
                </Row>

                <Row className="row-position-assessment">
                  {!isTrialActive ? (
                    <Col className="text-center">
                      <div className="alert alert-info mt-4">
                        Your trial hasn’t started yet. Please select a start
                        date to begin your ALPACA experience.
                      </div>
                    </Col>
                  ) : expired && !purchased ? (
                    <Col className="text-center">
                      <div className="alert alert-warning mt-4">
                        Your free trial has ended. To continue accessing
                        ALPACA’s features, please consider purchasing a
                        subscription.
                      </div>
                    </Col>
                  ) : filteredClasses.length === 0 ? (
                    <AlpacaInfoGesture speechText={speechText} />
                  ) : (
                    filteredClasses.map((item, index) => (
                      <AssessmentClassCard
                        key={`${item.id}-${index}`}
                        teachers={teachers}
                        classDetails={item}
                        rollNumber={rollNumber}
                        gradeLevelOptions={gradeLevelOptions}
                        teacherid={teacherid}
                      />
                    ))
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}

      {/* Modal for selecting trial start date */}
      <Modal
        show={showStartTrialModal}
        onHide={() => setShowStartTrialModal(false)}
        aria-labelledby="trial-modal-title"
        aria-describedby="trial-modal-description"
      >
        <Modal.Header closeButton>
          <Modal.Title id="trial-modal-title">Start Your Free Trial</Modal.Title>
        </Modal.Header>
        <Modal.Body id="trial-modal-description">
          {/* Display Start Date Information */}
          {start_selected ? (
            <p>
              Your trial is set to start on:{" "}
              <strong>{start_date ? format(new Date(start_date), "dd/MM/yyyy") : "Not set"}</strong>.
            </p>
          ) : (
            <div className="alert alert-warning">
              You have not yet selected a start date for your trial.
            </div>
          )}

          {/* Date Picker */}
          <p>Select a date to start your free trial:</p>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            className="form-control"
            dateFormat="dd/MM/yyyy" // Irish date format
            minDate={new Date()} // Prevent past dates
          />
        </Modal.Body>
        <Modal.Footer>
          {/* Button to Start Now */}
          <Button
            variant="success"
            onClick={async () => {
              try {
                const now = new Date();
                await updateTrialistStartDate(schoolid, now.toISOString());
                setSelectedDate(now);
                setShowStartTrialModal(false);
                alert("Trial start date set to now!");
                window.location.reload();
              } catch (error) {
                console.error("Error setting trial start date to now:", error);
              }
            }}
          >
            Start Now
          </Button>

          {/* Button to Confirm Selected Date */}
          <Button
            variant="primary"
            onClick={async () => {
              try {
                await updateTrialistStartDate(schoolid, selectedDate.toISOString());
                setShowStartTrialModal(false);
                alert("Trial start date has been updated.");
                window.location.reload();
              } catch (error) {
                console.error("Error setting trial start date:", error);
              }
            }}
          >
            Confirm Start Date
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AssessmentsDashboard;