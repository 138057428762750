import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./ClassOverview.css";
import ReturnToClassOverview from "./ReturnToClassOverview";
import BulkImportStudents from "./BulkImportStudents";
import AddedStudentsList from "./AddedStudentsList";
import AddStudentsFunction from "./AddStudentsFunction";
import SaveStudentsButton from "./SaveStudentsButton";
import GradeCard from "./GradeCard";
import { useNavigate } from "react-router-dom";
import {
  addStudents,
  getCountryGrades,
  getSchools,
  updateUserActivity
} from "../services/APIClient";

function AddStudentsOverview(props) {
  let classDetails = props.classDetails.classDetails;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers.teachers;
  let classid = classDetails.id;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let schoolid = props.schoolid;
  let isSpecialEdClass = props.isSpecialEdClass;
  let teacherId = props.teacherid;

  const navigate = useNavigate();

  const [newStudentDetails, setNewStudentDetails] = useState([]);
  const [finalStudentList, setFinalStudentList] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState({});
  const [countryGradeId, setCountryGradeId] = useState({});
  const [selectedGrades, setSelectedGrades] = useState({});
  const [countryId, setCountryId] = useState(null);

  console.log(props)

  const handleStudentSubmit = (e) => {
    // Add countryGradeId to each student
    const updatedStudentDetails = newStudentDetails.map((student) => ({
      ...student,
      countryGradeId: countryGradeId,
    }));

    addStudents(classid, updatedStudentDetails);
    updateUserActivity(teacherId, "students-added") // see useractivity column headings to know what to put in

    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  };

  useEffect(() => {
    console.log(selectedGrades, "GRADES");
  }, [selectedGrades]);

  useEffect(() => {
    getSchools()
      .then((response) => {
        const matchingSchool = response.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;
          setCountryGradeId(countryid);
          setCountryId(countryid); // Set country ID

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions(gradesResponse);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [schoolid]);

  const handleGradeChange = (selectedGrade, index) => {
    setSelectedGrades((prevGrades) => ({
      ...prevGrades,
      [index]: selectedGrade,
    }));

    // Update `currentGrade` in `newStudentDetails`
    setNewStudentDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      if (newDetails[index]) {
        newDetails[index].currentGrade = selectedGrade;
      }
      return newDetails;
    });
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-8">
            <h2 className="page-description-header">Add Students</h2>
            <p>Register your students by adding their names to the box below</p>
          </Col>
          <Col className="col-4">
            <Row className="h-100">
              <Col className="col-6">
                <GradeCard
                  gradeLevel={gradeLevel}
                  classDetails={classDetails}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
              <Col className="col-6"></Col>
            </Row>
          </Col>
        </Row>
        <hr></hr>
        <Row className="button-container">
          <Col className="col-8 vertical-border">
            <br></br>
            <AddStudentsFunction
              studentData={newStudentDetails}
              sendStudentData={setNewStudentDetails}
              studentDetails={finalStudentList}
            />
            <br></br>
            <br></br>
            <AddedStudentsList
              studentDetails={newStudentDetails}
              setStudentDetails={setNewStudentDetails}
              gradeLevelOptions={gradeLevelOptions}
              isSpecialEdClass={isSpecialEdClass}
              handleGradeChange={handleGradeChange}
              selectedGrades={selectedGrades}
              countryId={countryId}
            />
          </Col>
          <Col className="col-4 right-side-container">
            <BulkImportStudents />
            <div className="button-placer">
              <ReturnToClassOverview
                classDetails={classDetails}
                teachers={teachers}
                name={name}
                gradeLevel={gradeLevel}
                schoolid={schoolid}
              />
              <SaveStudentsButton
                classDetails={classDetails}
                teachers={teachers}
                studentDetails={finalStudentList}
                handleStudentSubmit={handleStudentSubmit}
                name={name}
                gradeLevel={gradeLevel}
                schoolid={schoolid}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddStudentsOverview;
