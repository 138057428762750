import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button, Modal, Dropdown } from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { getIndividualStudentResults, createRti, getRtiRecords, getAllTaskRecommendations } from "../services/APIClient";
import "./RTIOverview.css";

function RTIOverview({
  student = {},
  taskDetails,
  studentName,
  recommendationsLink = "/path/to/recommendation.pdf",
  contactEmail = "info@alpaca-assessment.com",
  teacherid,
  studentid,
}) {

  const [individualStudentResult, setIndividualStudentResult] = useState([]);
  const [interventionType, setInterventionType] = useState("weekly"); // daily or weekly
  const [interventionCount, setInterventionCount] = useState(1); // 1-9
  const [interventionStartDate, setInterventionStartDate] = useState("");
  const [interventionEndDate, setInterventionEndDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isExistingIntervention, setIsExistingIntervention] = useState(false); // Flag for existing intervention
  const [recommendationLink, setRecommendationLink] = useState("");
  const [recommendationDuration, setRecommendationDuration] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const interventionName = `${studentName} - ${taskDetails?.name || "Task"} Intervention`;
  const score = Math.round(taskDetails?.score * 100);

  useEffect(() => {
    if (studentid) {
      getIndividualStudentResults(studentid)
        .then((response) => setIndividualStudentResult(response))
        .catch((err) => console.error(err));
    }

    // Fetch task recommendations and set link based on task name
    fetchRecommendationLink();

    // Set recommendation duration based on score
    determineRecommendationDuration(score);
  }, [studentid, taskDetails?.name]);

  const fetchRecommendationLink = async () => {
    try {
      const recommendations = await getAllTaskRecommendations();
      console.log(recommendations);

      // Use `includes` to find a recommendation with a partial match
      const matchingRecommendation = recommendations.find((rec) =>
        taskDetails?.name && rec.name.toLowerCase().includes(taskDetails.name.toLowerCase())
      );

      setRecommendationLink(matchingRecommendation?.files.all || "");
    } catch (error) {
      console.error("Error fetching task recommendations:", error);
    }
  };


  const determineRecommendationDuration = (score) => {
    if (score < 5) {
      setRecommendationDuration("8-week");
    } else if (score >= 5 && score < 15) {
      setRecommendationDuration("6-week");
    } else if (score >= 15 && score < 30) {
      setRecommendationDuration("4-week");
    } else {
      setRecommendationDuration("2-week");
    }
  };

  useEffect(() => {
    // Fetch individual student results
    if (studentid) {
      getIndividualStudentResults(studentid)
        .then((response) => setIndividualStudentResult(response))
        .catch((err) => console.error(err));
    }

    // Check for existing RTI intervention
    fetchRtiRecord();

  }, [studentid, teacherid, taskDetails?.taskid]);

  const fetchRtiRecord = async () => {
    try {
      const rtiRecords = await getRtiRecords({
        teacherid,
        studentid,
        taskid: taskDetails?.taskid,
      });

      if (rtiRecords && rtiRecords.length > 0) {
        const existingRecord = rtiRecords[0]; // Assuming the first record is the relevant one
        prepopulateIntervention(existingRecord);
        setIsExistingIntervention(true); // Set flag to indicate prepopulation
      }
    } catch (error) {
      console.error("Error fetching RTI record:", error);
    }
  };

  const prepopulateIntervention = (record) => {
    setInterventionType(record.interventionscheduletype);
    setInterventionCount(record.interventionschedule);
    setInterventionStartDate(record.interventionschedule_startdate);
    setInterventionEndDate(calculateEndDateFromStartDate(record.interventionschedule_startdate, record.interventionscheduletype, record.interventionschedule));
  };

  const calculateEndDateFromStartDate = (startDate, type, count) => {
    let currentDate = new Date(startDate);
    while (count > 0) {
      currentDate.setDate(currentDate.getDate() + (type === "weekly" ? 7 : 1));
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        count -= 1;
      }
    }
    return currentDate;
  };

  const handleStartClick = () => {
    if (!interventionStartDate) {
      alert("Please select a start date.");
      return;
    }

    const calculatedEndDate = calculateEndDateFromStartDate(interventionStartDate, interventionType, interventionCount);
    setInterventionEndDate(calculatedEndDate);
    setShowModal(true);
  };

  // Helper function to format date to "YYYY-MM-DD"
  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    return `${year}-${month}-${day}`;
  };


  const confirmIntervention = async () => {
    const rtiData = {
      teacherid: teacherid,
      studentid: studentid,
      taskid: taskDetails?.taskid || "",
      interventionscheduletype: interventionType,
      interventionschedule: interventionCount,
      interventionschedule_startdate: formatDate(new Date(interventionStartDate)), // Format for backend
      interventionschedule_enddate: formatDate(new Date(interventionEndDate)), // Format for backend
      current_week: "1",
      name: interventionName,
      grade_level1: false,
      grade_level2: false,
      steps: [
        {
          taskid: taskDetails?.taskid || "",
          order: 1,
        },
      ],
    };

    try {
      const createdRti = await createRti(rtiData);
      console.log("RTI record created:", createdRti);
      setShowModal(false);
      setShowConfirmationModal(true);
    } catch (error) {
      console.error("Error creating RTI:", error);
    }
  };



  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="main-content gx-4">
          <Col lg={6} md={12} className="recommendation-column">
            <div className="score-indicator existing-score">
              <span>{taskDetails?.name}: {Math.round(taskDetails?.score * 100)}%</span>
            </div>

            <div className="recommendation-section">
              <h3>Recommendation</h3>
              <div className="recommendation-content">
                <p>
                  <strong>{studentName}</strong> scored <strong>{score}%</strong> in <strong>{taskDetails?.name}</strong>.
                </p>
                <p>
                  We have put together the following resources to assist you in improving <strong>{studentName}'s</strong> <strong>{taskDetails?.name}</strong> ability.
                </p>
                {recommendationLink && (
                  <a href={recommendationLink} target="_blank" rel="noopener noreferrer">
                    {taskDetails?.name} Recommendations PDF
                  </a>
                )}
                <p>
                  Based on <strong>{studentName}'s</strong> result in this area, we suggest a <strong>{recommendationDuration} {taskDetails?.name} intervention schedule</strong>.
                  However, this is up to your discretion, and you can adjust the intervention within the Intervention Schedule.
                </p>
                <p>
                  For further information or assistance, please contact us at <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
                </p>
              </div>
            </div>
          </Col>

          <Col lg={6} md={12} className="rti-column">
            <div className="score-indicator soon-score">
              <span>RTI {taskDetails?.name}: </span>
            </div>

            <div className="rti-details-section">
              <h3>Response to Intervention</h3>

              <div className="mb-3">
                <label>Task for Intervention:</label>
                <div className="form-control-plaintext">
                  {interventionName}
                </div>
              </div>

              <div className="mb-3">
                <label>Intervention Schedule Type:</label>
                <Dropdown onSelect={(eventKey) => setInterventionType(eventKey)} disabled={isExistingIntervention}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {interventionType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="daily">Daily</Dropdown.Item>
                    <Dropdown.Item eventKey="weekly">Weekly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="mb-3">
                <label>Intervention Schedule Count:</label>
                <Dropdown onSelect={(eventKey) => setInterventionCount(parseInt(eventKey, 10))} disabled={isExistingIntervention}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {interventionCount}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {[...Array(9)].map((_, index) => (
                      <Dropdown.Item key={index + 1} eventKey={index + 1}>
                        {index + 1}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="mb-3">
                <label>Intervention Schedule Start Date:</label>
                <input
                  type="date"
                  value={interventionStartDate}
                  onChange={(e) => setInterventionStartDate(e.target.value)}
                  className="form-control"
                  disabled={isExistingIntervention}
                />
              </div>

              {interventionEndDate && (
                <div className="mb-3">
                  <label>Intervention Schedule End Date:</label>
                  <div className="form-control-plaintext">
                    {new Date(interventionEndDate).toLocaleDateString()}
                  </div>
                </div>
              )}



              <Button variant="success" className="start-button" onClick={handleStartClick} disabled={isExistingIntervention}>
                Start
              </Button>
            </div>

            <div className="calendar-section">
              <Calendar
                value={new Date()}
                tileClassName={({ date, view }) => {
                  if (view === "month") {
                    if (isSameDay(date, new Date(interventionStartDate))) {
                      return "highlight-start";
                    } else if (isSameDay(date, new Date(interventionEndDate))) {
                      return "highlight-end";
                    } else if (
                      date > new Date(interventionStartDate) &&
                      date < new Date(interventionEndDate)
                    ) {
                      return "highlight-range";
                    }
                  }
                  return null;
                }}
              />
            </div>
          </Col>
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Intervention</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You have selected an intervention from{" "}
              {interventionStartDate ? new Date(interventionStartDate).toLocaleDateString() : "N/A"}{" "}
              to {interventionEndDate ? interventionEndDate.toLocaleDateString() : "N/A"}.
            </p>
            <p>Do you want to proceed?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmIntervention}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Intervention Scheduled</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Intervention details for <strong>{interventionName}</strong> have been successfully saved.
            </p>
            <p>Schedule: <strong>{interventionType}</strong></p>
            <p>Duration: <strong>{interventionCount} {interventionType === "weekly" ? "weeks" : "days"}</strong></p>
            <p>Start Date: <strong>{new Date(interventionStartDate).toLocaleDateString()}</strong></p>
            <p>End Date: <strong>{new Date(interventionEndDate).toLocaleDateString()}</strong></p>
            <p>An email containing these details will be sent to <strong>{contactEmail}</strong> shortly.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowConfirmationModal(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

      </Container>
    </div>
  );
}

export default RTIOverview;
