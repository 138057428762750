import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import RTIOverview from "../components/RTIOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

function RTI(props) {
  const location = useLocation();

  // Destructure props received through navigation state
  const {
    studentResults,
    isPrintOut,
    detailedView,
    studentName,
    teacherid,
    taskDetails,
    gradeLevel,
    classAssessments,
    teachers,
    assessment,
    student,
    taskNameArray,
    assessmentResultsByStudent,
    toggleScoreType,
    schoolid,
    studentid,
  } = location.state || {};

  console.log("Location State:", location.state); // Log to see all data received

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={`Response to Intervention - ${studentName}`}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />

      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <RTIOverview
            studentResults={studentResults}
            isPrintOut={isPrintOut}
            detailedView={detailedView}
            studentName={studentName}
            taskDetails={taskDetails} // Newly added task details
            classAssessments={classAssessments}
            gradeLevel={gradeLevel}
            teachers={teachers}
            assessment={assessment}
            student={student}
            taskNameArray={taskNameArray}
            assessmentResultsByStudent={assessmentResultsByStudent}
            toggleScoreType={toggleScoreType}
            schoolid={schoolid}
            teacherid={teacherid}
            studentid={studentid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default RTI;
