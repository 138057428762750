import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// import button from 'react-bootstrap/button';

import "./AddStudentsButton.css";

function AddStudentsbutton(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let schoolid = props.schoolid;
  let isSpecialEdClass = props.isSpecialEdClass;
  let teacherid = props.teacherid;

  return (
    <Link
      to={`/addstudents`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        sharedWith: { sharedWith },
        name: { name },
        gradeLevel: { gradeLevel },
        schoolid: { schoolid },
        isSpecialEdClass: { isSpecialEdClass },
        teacherid: { teacherid },
      }}
    >
      <button className="light-blue-button add-pupils-button">
        Add Students <img src="/assets/imgs/add.svg" />
      </button>
    </Link>
  );
}

export default AddStudentsbutton;
