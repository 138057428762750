import React, { useEffect, useState } from "react";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import { useNavigate } from "react-router-dom";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  createCustomAssessment,
  getCustomAssessments,
  createCustomAssessmentSteps,
  getTasksByGradeLevel,
  getCustomAssessmentSteps,
} from "../services/APIClient";
import { Container, Row, Col, Button, Modal, Form, Card } from "react-bootstrap";
import "../components/AssessmentClassCard.css";
import OrientationPrompt from "../components/OrientationPrompt";

function CustomAssessmentsDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [customAssessments, setCustomAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Modal-related state
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [newAssessmentName, setNewAssessmentName] = useState("");
  const [gradeLevel1, setGradeLevel1] = useState(false);
  const [gradeLevel2, setGradeLevel2] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskOrders, setTaskOrders] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]); // Track selected order numbers
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const navigate = useNavigate();

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
    });
  }, []);

  useEffect(() => {
    async function fetchSchoolData() {
      try {
        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);

        const classesResponse = await getClasses();
        const uniqueClasses = classesResponse.filter(
          (classItem, index, self) =>
            index === self.findIndex((t) => t.id === classItem.id)
        );
        uniqueClasses.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setClasses(uniqueClasses);

        if (teacherid) {
          const customAssessmentsResponse = await getCustomAssessments(teacherid);
          setCustomAssessments(customAssessmentsResponse);
        }

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions([gradesResponse]);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
      setLoading(false);
    }

    if (schoolid) {
      fetchSchoolData();
    }
  }, [schoolid, teacherid]);

  useEffect(() => {
    if (name) {
      const nameArray = name.split(" ");
      setFirstname(nameArray[0]);
    }
  }, [name]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleCreateAssessment = async () => {
    try {
      const newAssessment = await createCustomAssessment(
        teacherid,
        newAssessmentName,
        gradeLevel1,
        gradeLevel2
      );
      setCustomAssessments((prev) => [...prev, newAssessment]);
      setShowCreateModal(false);
    } catch (error) {
      console.error("Error creating custom assessment:", error);
    }
  };

  const handleShowTasks = async (assessment) => {
    setSelectedAssessment(assessment);
    try {
      let fetchedTasks = [];
      let fetchedSteps = [];

      if (assessment.grade_level1) {
        const grade1Tasks = await getTasksByGradeLevel(1);
        fetchedTasks = fetchedTasks.concat(grade1Tasks);
      }
      if (assessment.grade_level2) {
        const grade2Tasks = await getTasksByGradeLevel(2);
        fetchedTasks = fetchedTasks.concat(grade2Tasks);
      }

      // Fetch existing assessment steps
      fetchedSteps = await getCustomAssessmentSteps(assessment.id);

      // Initialize taskOrders and selectedOrders based on existing steps
      const initialTaskOrders = {};
      const initialSelectedOrders = [];

      fetchedSteps.forEach((step) => {
        initialTaskOrders[step.taskid] = step.order;
        initialSelectedOrders.push(step.order);
      });

      setTasks(fetchedTasks);
      setTaskOrders(initialTaskOrders);
      setSelectedOrders(initialSelectedOrders);
      setShowTaskModal(true);
    } catch (error) {
      console.error("Error fetching tasks or assessment steps:", error);
    }
  };

  const handleOrderChange = (taskId, selectedOrder) => {
    setTaskOrders((prevOrders) => ({
      ...prevOrders,
      [taskId]: parseInt(selectedOrder)
    }));

    setSelectedOrders((prevSelected) => {
      const newSelected = prevSelected.filter((order) => order !== taskOrders[taskId]);
      newSelected.push(parseInt(selectedOrder));
      return newSelected;
    });
  };

  const handleSaveScreener = async () => {
    const orderedSteps = tasks
      .filter((task) => taskOrders[task.id] > 0)
      .map((task) => ({
        taskid: task.id,
        order: taskOrders[task.id]
      }))
      .sort((a, b) => a.order - b.order);

    if (orderedSteps.length === 0) {
      alert("Please assign an order to at least one task to create a screener.");
      return;
    }

    try {
      await createCustomAssessmentSteps(selectedAssessment.id, orderedSteps);
      alert("Screener saved successfully!");
      setShowTaskModal(false);
      setTaskOrders({});
      setSelectedAssessment(null);
    } catch (error) {
      console.error("Error saving screener:", error);
      alert("There was an error saving the screener.");
    }
  };

  const getAvailableOptions = (taskId) => {
    const maxOptions = tasks.length;
    return Array.from({ length: maxOptions }, (_, i) => i + 1).filter(
      (num) => !selectedOrders.includes(num) || num === taskOrders[taskId]
    );
  };

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`${name}'s Screener`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
        />
        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                <Row className="row-position">
                  <Col xs={4} className="text-end">
                    <Button onClick={() => setShowCreateModal(true)}>
                      Create Custom Assessment
                    </Button>
                  </Col>
                </Row>
                <Row className="row-position-assessment">
                  {customAssessments.length > 0 ? (
                    customAssessments.map((assessment) => (
                      <Col md={4} key={assessment.id}>
                        <Card onClick={() => handleShowTasks(assessment)} className="my-3">
                          <Card.Body>
                            <Card.Title>{assessment.name}</Card.Title>
                            <Card.Text>
                              Grade Levels: {assessment.grade_level1 ? "1" : ""} {assessment.grade_level2 ? "2" : ""}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <p>No custom assessments found.</p>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAssessmentName">
              <Form.Label>Assessment Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter assessment name"
                value={newAssessmentName}
                onChange={(e) => setNewAssessmentName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGradeLevel1">
              <Form.Check
                type="checkbox"
                label="Grade Level 1"
                checked={gradeLevel1}
                onChange={(e) => setGradeLevel1(e.target.checked)}
              />
            </Form.Group>
            <Form.Group controlId="formGradeLevel2">
              <Form.Check
                type="checkbox"
                label="Grade Level 2"
                checked={gradeLevel2}
                onChange={(e) => setGradeLevel2(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateAssessment}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTaskModal} onHide={() => setShowTaskModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Tasks to {selectedAssessment?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tasks.map((task) => (
            <Row key={task.id} className="align-items-center mb-2">
              <Col xs={8}>
                <span>{task.name}</span>
              </Col>
              <Col xs={4}>
                <Form.Control
                  as="select"
                  value={taskOrders[task.id] || ""}
                  onChange={(e) => handleOrderChange(task.id, e.target.value)}
                >
                  <option value="">Select order</option>
                  {getAvailableOptions(task.id).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTaskModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveScreener}>
            Save Screener
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomAssessmentsDashboard;
