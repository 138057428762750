import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import ClassCard from "../components/ClassCard";
import CreateClassCard from "../components/CreateClassCard";
import CreateTrialClassCard from "../components/CreateTrialClassCard";
import CreateSpecialEdClassCard from "../components/CreateSpecialEdClassCard";
import CreateSETClassCard from "../components/CreateSETClassCard";
import LeftPanel from "../components/LeftPanel";
import ClassFilter from "../components/ClassFilter";
import OrientationPrompt from "../components/OrientationPrompt";
import FreeTrialBanner from "../components/FreeTrialBanner";
import { Modal, Button } from "react-bootstrap";

import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  shareClass,
  getTrialistsBySchool
} from "../services/APIClient";
import "../components/ClassCard.css";

function ClassAdminDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [grade2, setGrade2] = useState([]);
  const [grade1, setGrade1] = useState([]);
  const [mostRecentClassId, setMostRecentClassId] = useState(null);
  const [teacherRoles, setTeacherRoles] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [showTrialEndedModal, setShowTrialEndedModal] = useState(false);
  const {
    expired = false,
    purchased = false,
  } = trialistDetails || {};


  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const [filteredClassObjects, setFilteredClassObjects] = useState([]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    whoami().then((response) => {
      console.log("response:", response);
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherEmail(response.data.email);
      setTeacherRoles(response.data.stakeholder.additionalRoles);
    });
  }, []);

  useEffect(() => {
    async function fetchSchoolData() {
      try {
        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);

        const classesResponse = await getClasses();
        console.log("Classes Response:", classesResponse);

        // Remove duplicates based on `id`
        const uniqueClasses = Array.from(
          new Set(classesResponse.map((cls) => cls.id))
        ).map((id) => {
          return classesResponse.find((cls) => cls.id === id);
        });

        if (Array.isArray(uniqueClasses) && uniqueClasses.length > 0) {
          // Apply filtering and sorting to uniqueClasses
          const filtered = uniqueClasses.filter((cls) => {
            const createdYear = new Date(cls.date_created).getFullYear();
            const updatedYear = new Date(cls.date_updated).getFullYear();
            return createdYear === 2023 && updatedYear === 2024;
          });

          if (filtered.length > 0) {
            setFilteredClassObjects(filtered);
            console.log("Filtered Class Objects:", filtered);
          } else {
            console.log("No classes match the criteria.");
          }

          // Sort classes: Newly created classes first, then those needing a new teacher
          const sortedClasses = uniqueClasses.sort((a, b) => {
            const aDateCreated = new Date(a.date_created);
            const bDateCreated = new Date(b.date_created);

            // Sort by creation date (most recent first)
            if (aDateCreated > bDateCreated) return -1;
            if (aDateCreated < bDateCreated) return 1;

            // If the creation dates are the same, fall back to other sorting criteria
            const aNeedsAssign = a.name.includes(
              "Assign New Classroom teacher"
            );
            const bNeedsAssign = b.name.includes(
              "Assign New Classroom teacher"
            );

            return bNeedsAssign - aNeedsAssign;
          });

          setClasses(sortedClasses);

          setClasses(sortedClasses);

          setClasses(sortedClasses);

          // Log the sorted classes order
          console.log("Sorted Classes Order:");
          sortedClasses.forEach((cls, index) => {
            console.log(`Position ${index + 1}:`, cls.name);
          });

          const recentClass = sortedClasses[0];
          setMostRecentClassId(recentClass.id);
          console.log("Most Recent Class ID:", recentClass.id);
        } else {
          console.log(
            "No classes available or classesResponse is not an array."
          );
        }

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;
          setCountryId(countryid);
          setRollNumber(rollNumber);

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions([gradesResponse]);
              setGrade2(gradesResponse.grade2);
              setGrade1(gradesResponse.grade1);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      } finally {
        setLoading(false);
      }
    }

    if (schoolid) {
      fetchSchoolData();
    }
  }, [schoolid]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  const handleShareClass = async () => {
    const selectedTeacherId = localStorage.getItem("selectedTeacherId");

    if (selectedTeacherId && mostRecentClassId) {
      try {
        await shareClass(mostRecentClassId, selectedTeacherId);
        console.log("shareClass function has been fired with:", {
          classId: mostRecentClassId,
          teacherId: selectedTeacherId,
        });
        localStorage.removeItem("selectedTeacherId");
      } catch (error) {
        console.error("Error sharing the class:", error);
      }
    } else {
      console.log("No selectedTeacherId or mostRecentClassId available.");
    }
  };

  useEffect(() => {
    handleShareClass();
  }, [mostRecentClassId]);

  // Inline styles for the white box
  const whiteBoxStyle = {
    backgroundColor: "white",
    border: "1px solid #ddd",
    padding: "20px",
    borderRadius: "8px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0 auto", // Center the white box
    marginBottom: "20px",
    width: "60%", // Adjust the width as needed
  };

  // Sorting filteredClasses: New classes first, then those needing a new teacher
  const sortedFilteredClasses = Array.from(new Set(filteredClasses)).sort(
    (a, b) => {
      const aDateCreated = new Date(a.date_created);
      const bDateCreated = new Date(b.date_created);

      // Sort by creation date (most recent first)
      if (aDateCreated > bDateCreated) return -1;
      if (aDateCreated < bDateCreated) return 1;

      // Fall back to existing filtered class criteria
      const aInFiltered = filteredClassObjects.some((obj) => obj.id === a.id);
      const bInFiltered = filteredClassObjects.some((obj) => obj.id === b.id);
      if (aInFiltered && !bInFiltered) return -1;
      if (!aInFiltered && bInFiltered) return 1;

      return 0;
    }
  );

  console.log("teacherRoles:", teacherRoles);
  console.log("countryid:", countryId);

  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid); // Fetch data
        if (response.length > 0) {
          const details = response[0]; // Assuming we use the first trial entry
          setTrialistDetails(details);

          // Calculate freeDaysLeft
          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          const timeDiff = endDate - currentDate; // Difference in milliseconds
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
          setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0); // Ensure non-negative value
        }
      } catch (error) {
        console.error("Failed to fetch trialist details:", error);
      }
    };
    fetchTrialistDetails();
  }, [schoolid]);

  const handleTrialEndedClick = () => {
    setShowTrialEndedModal(true);
  };

  const handleCloseTrialEndedModal = () => {
    setShowTrialEndedModal(false);
  };


  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`Welcome ${name}!`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />
        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                <Row className="row-position">
                  {!purchased ? (
                    <FreeTrialBanner
                      schoolid={schoolid}
                      teacherName={name}
                      freeDaysLeft={freeDaysLeft}
                      trialistDetails={trialistDetails}
                    />
                  ) : (
                    null
                  )}
                  {classes.length > 0 && (
                    <ClassFilter
                      classes={classes}
                      setFilteredClasses={setFilteredClasses}
                      gradeLevelOptions={gradeLevelOptions}
                      teacherRoles={teacherRoles}
                    />
                  )}
                  <Row>
                    {expired && !purchased ? (
                      <div
                        className="disabled-card-wrapper"
                        onClick={handleTrialEndedClick}
                      >
                        <CreateClassCard
                          schoolid={schoolid}
                          teacherid={teacherid}
                          name={name}
                          classes={classes}
                          rollNumber={rollNumber}
                          teacherRoles={teacherRoles}
                          countryId={countryId}
                        />
                        <CreateTrialClassCard
                          schoolid={schoolid}
                          teacherid={teacherid}
                          name={name}
                          classes={classes}
                          teacherRoles={teacherRoles}
                          countryId={countryId}
                        />
                      </div>
                    ) : (
                      <>
                        <CreateClassCard
                          schoolid={schoolid}
                          teacherid={teacherid}
                          name={name}
                          classes={classes}
                          rollNumber={rollNumber}
                          teacherRoles={teacherRoles}
                          countryId={countryId}
                        />
                        <CreateTrialClassCard
                          schoolid={schoolid}
                          teacherid={teacherid}
                          name={name}
                          classes={classes}
                          teacherRoles={teacherRoles}
                          countryId={countryId}
                        />
                      </>
                    )}
                  </Row>

                  {teacherRoles?.includes("SENCO") && (
                    <CreateSETClassCard
                      schoolid={schoolid}
                      teacherid={teacherid}
                      name={name}
                      classes={classes}
                      teacherRoles={teacherRoles}
                      countryId={countryId}
                    />
                  )}
                  {/* <CreateSpecialEdClassCard
                    schoolid={schoolid}
                    teacherid={teacherid}
                    name={name}
                    classes={classes}
                    teacherRoles={teacherRoles}
                    countryId={countryId}
                  /> */}
                </Row>

                {/* New Div with inline styles */}
                {/* {countryId !== "74bb2897-dbbe-41e3-b547-caee866545cf" && (
                  <Row className="my-3">
                    <Col>
                      <div style={whiteBoxStyle}>
                        23/24 {grade1} classrooms were converted to {grade2}{" "}
                        classrooms.
                        <br />
                        <br /> {grade2} teachers, mark yourself as the new{" "}
                        {grade2} teacher to claim your classroom!
                      </div>
                    </Col>
                  </Row>
                )} */}

                <Row className="class-row-position">
                  {sortedFilteredClasses.map((item) => (
                    <ClassCard
                      key={item.id}
                      teachers={teachers}
                      classDetails={item}
                      rollNumber={rollNumber}
                      teacherEmail={teacherEmail}
                      schoolid={schoolid}
                      filteredClassObjects={filteredClassObjects}
                      expired={expired}
                      purchased={purchased}
                      teacherRoles={teacherRoles}
                    />
                  ))}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}
      <Modal show={showTrialEndedModal} onHide={handleCloseTrialEndedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Trial Ended</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your trial period has ended. To continue enjoying ALPACA’s full
          features, please proceed with a purchase.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseTrialEndedModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClassAdminDashboard;
