import { React, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { getIndividualStudentResults } from "../services/APIClient";

import "./IndividualStudentResultsOverview.css";
import "./ClassOverview.css";

import ReturnToResults from "./ReturnToResults";

import ReturnToClassResults from "./RetrunToClassResults";

import SelectedResults from "./SelectedResults";

import ResultsHistory from "./ResultsHistory";

import RecommendationsButton from "./RecommendationsButton";

import IndividualStudentResultsTable from "./IndividualStudentTaskResultsTable";

import ScoreToggleButton from "./ScoreToggleButton";

import GradeCard from "./GradeCard";

import TestIndividual from "./TestIndividual";

import PrintStudentResults from "./PrintStudentResults";

import ExportStudentResults from "./ExportStudentResults";

import {preprocessStudentResults} from "../services/GroupAndAverageTasks";


function IndividualStudentResultsOverview(props) {
  const {
    classDetails,
    classAssessments,
    gradeLevel,
    teachers,
    assessment,
    student,
    assessmentResultsByStudent,
    schoolid,
    teacherid,
    fromSencoResults,
  } = props;

  const { id: studentid, name: studentName } = student;
  const {
    grade_level1: gradeLevel1,
    grade_level2: gradeLevel2,
    trial_class: trialClass,
  } = classDetails;

  const [individualStudentResult, setIndividualStudentResult] = useState([]);

  const [toggleScoreType, setToggleScoreType] = useState("");
  const [grade1Results, setGrade1Results] = useState([]);
  const [grade2Results, setGrade2Results] = useState([]);
  const [grade1Prefix, setGrade1Prefix] = useState("");
  const [grade2Prefix, setGrade2Prefix] = useState("");
  const [showCurrentOnly, setShowCurrentOnly] = useState(true);

  useEffect(() => {
    getIndividualStudentResults(studentid)
      .then((response) => {
        setIndividualStudentResult(response);

        const grade1 = preprocessStudentResults(response).filter((result) => result.grade_level1);
        const grade2 = preprocessStudentResults(response).filter((result) => result.grade_level2);
        setGrade1Results(grade1);
        setGrade2Results(grade2);

        if (grade1.length > 0) {
          setGrade1Prefix(grade1[0].name.split(" ")[0]);
        }
        if (grade2.length > 0) {
          setGrade2Prefix(grade2[0].name.split(" ")[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [studentid]);



  console.log("individualStudentResult:", preprocessStudentResults(individualStudentResult));

  // console.log("grade1Results:", grade1Results)
  // console.log("grade2Results:", grade2Results)

  const filteredGrade1Results = showCurrentOnly
    ? grade1Results.filter((result) => result.name === assessment.name)
    : grade1Results;

  const filteredGrade2Results = showCurrentOnly
    ? grade2Results.filter((result) => result.name === assessment.name)
    : grade2Results;

  const filteredIndividualResults = showCurrentOnly
    ? preprocessStudentResults(individualStudentResult).filter(
        (result) => result.name === assessment.name
      )
    : preprocessStudentResults(individualStudentResult);

  // Toggle function
  const toggleView = () => {
    setShowCurrentOnly(!showCurrentOnly);
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <Row>
              <Col className="col-6">
                <h2 className="page-description-header">
                  {classDetails.name}
                  {/* {classDetails.name} -{" "}
                {toggleScoreType ? "Percentile" : "Percentage"} */}
                </h2>
              </Col>
              <Col className="col-4">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-3"></Col>
              <Col className="col-3">
                <ReturnToClassResults
                  classDetails={classDetails}
                  teachers={teachers}
                  classAssessments={classAssessments}
                  assessment={assessment}
                  schoolid={schoolid}
                  teacherid={teacherid}
                  fromSencoResults={fromSencoResults}
                />
              </Col>
              <Col className="col-1"></Col>
              <Col className="col-4">
                <RecommendationsButton onResultsPage={true} />
              </Col>

              <Col className="col-6">
                {/* <ScoreToggleButton setScoreType={setToggleScoreType} /> */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <p>
            View the results for an individual student. Click on an screener to
            see a more detailed breakdown.
          </p>
        </Row>
        <Row>
          <div className="toggle-container">
            <h5 className="page-description-header">
              Toggle between current and all assessments{" "}
            </h5>
            <label className="switch">
              <input type="checkbox" onChange={toggleView} />
              <span className="slider round"></span>
            </label>
          </div>
        </Row>

        <Row className="button-container">
          <Col className="col-2"></Col>
        </Row>

        <hr></hr>
        <Row className="button-container">
          <Col className="col-2">
            {/* <GradeCard gradeLevel={gradeLevel} /> */}
          </Col>
          <Col className="col-2">
            {/* <ScoreToggleButton toggleScoreType={toggleScoreType} /> */}
          </Col>
        </Row>
        <Row>
          <Col className="col-3 pr-2">
            {" "}
            <h3 className="page-description-header">{student.name}</h3>
          </Col>
          <Col className="col-2 pl-2">
            {" "}
            {/* <GradeCard
              gradeLevel={gradeLevel}
              gradeLevel1={gradeLevel1}
              gradeLevel2={gradeLevel2}
              trialClass={trialClass}
            /> */}
          </Col>
        </Row>
        <br></br>

        {grade1Results.length > 0 && grade2Results.length > 0 ? (
          <Tabs
            defaultActiveKey="grade1"
            id="grade-tabs"
            className="custom-tabs"
          >
            <Tab eventKey="grade1" title={`${grade1Prefix} Level Results`}>
              <Row>
                <Col className="col-3">
                  <PrintStudentResults
                    individualStudentResult={grade1Results}
                    toggleScoreType={toggleScoreType}
                    assessmentResultsByStudent={assessmentResultsByStudent}
                    student={student}
                    classDetails={classDetails}
                    detailedView={false}
                    buttonName={"Print Basic"}
                  />
                </Col>
                <Col className="col-3">
                  <PrintStudentResults
                    individualStudentResult={grade1Results}
                    toggleScoreType={toggleScoreType}
                    assessmentResultsByStudent={assessmentResultsByStudent}
                    student={student}
                    classDetails={classDetails}
                    detailedView={true}
                    buttonName={"Print Detailed"}
                  />
                </Col>
                <Col className="col-8"></Col>
              </Row>
              {grade1Results.length > 0 ? (
                <ResultsHistory
                  individualStudentResult={filteredGrade1Results}
                  toggleScoreType={toggleScoreType}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  isPrintOut={false}
                  detailedView={true}
                  studentName={studentName}
                  teacherid={teacherid}
                  schoolid={schoolid}
                  assessment={assessment}
                  studentid={studentid}
                />
              ) : (
                <Row>
                  <Col>
                    <p>No answers registered for Grade Level 1 assessments.</p>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="grade2" title={`${grade2Prefix} Level Results`}>
              <Row>
                <Col className="col-3">
                  <PrintStudentResults
                    individualStudentResult={filteredGrade2Results}
                    toggleScoreType={toggleScoreType}
                    assessmentResultsByStudent={assessmentResultsByStudent}
                    student={student}
                    classDetails={classDetails}
                    detailedView={false}
                    buttonName={"Print Basic"}
                  />
                </Col>
                <Col className="col-3">
                  <PrintStudentResults
                    individualStudentResult={grade2Results}
                    toggleScoreType={toggleScoreType}
                    assessmentResultsByStudent={assessmentResultsByStudent}
                    student={student}
                    classDetails={classDetails}
                    detailedView={true}
                    buttonName={"Print Detailed"}
                  />
                </Col>
                <Col className="col-8"></Col>
              </Row>
              {grade2Results.length > 0 ? (
                <ResultsHistory
                  individualStudentResult={filteredGrade2Results}
                  toggleScoreType={toggleScoreType}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  isPrintOut={false}
                  detailedView={true}
                  studentName={studentName}
                  teacherid={teacherid}
                  schoolid={schoolid}
                  assessment={assessment}
                  studentid={studentid}
                />
              ) : (
                <Row>
                  <Col>
                    <p>No answers registered for Grade Level 2 assessments.</p>
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        ) : (
          <>
            <Row>
              <Col className="col-3">
                <PrintStudentResults
                  individualStudentResult={preprocessStudentResults(individualStudentResult)}
                  toggleScoreType={toggleScoreType}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  student={student}
                  classDetails={classDetails}
                  detailedView={false}
                  buttonName={"Print Basic"}
                />
              </Col>
              <Col className="col-3">
                <PrintStudentResults
                  individualStudentResult={preprocessStudentResults(individualStudentResult)}
                  toggleScoreType={toggleScoreType}
                  assessmentResultsByStudent={assessmentResultsByStudent}
                  student={student}
                  classDetails={classDetails}
                  detailedView={true}
                  buttonName={"Print Detailed"}
                />
              </Col>
              <Col className="col-8"></Col>
            </Row>
            {preprocessStudentResults(individualStudentResult).length > 0 ? (
              <ResultsHistory
                individualStudentResult={filteredIndividualResults}
                toggleScoreType={toggleScoreType}
                assessmentResultsByStudent={assessmentResultsByStudent}
                isPrintOut={false}
                detailedView={true}
                studentName={studentName}
                teacherid={teacherid}
                schoolid={schoolid}
                assessment={assessment}
                studentid={studentid}
              />
            ) : (
              <Row>
                <Col>
                  <p>
                    No answers registered for this student. Please rescan the
                    student to start the screener.
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default IndividualStudentResultsOverview;
