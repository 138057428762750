import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

import HeadingBanner from "../components/HeadingBanner";
import AlpacaInfoGesture from "../components/AlpacaInfoGesture";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import FreeTrialBanner from "../components/FreeTrialBanner";


import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getTrialistsBySchool
} from "../services/APIClient";

import { Container, Row, Col } from "react-bootstrap";

import "../components/ClassCard.css";
import "../components/AdminDashCards.css";

function AdminDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classid, setClassid] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trialistDetails, setTrialistDetails] = useState(null);
  const [freeDaysLeft, setFreeDaysLeft] = useState(null);
  const [showTrialEndedModal, setShowTrialEndedModal] = useState(false);
  const {
    expired = false,
    purchased = false,
  } = trialistDetails || {};
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherEmail(response.data.email);
    });
  }, []);

  useEffect(() => {
    if (schoolid) {
      Promise.all([
        getTeachers(schoolid),
        getClasses(),
        getSchools()
      ]).then(([teachersResponse, classesResponse, schoolsResponse]) => {
        setTeachers(teachersResponse);
        setClasses(classesResponse);
        setClassid(classesResponse.id);

        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          setRollNumber(rollNumber);;
        }
      }).catch((error) => {
        console.error("Error fetching data:", error);
      }).finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    }
  }, [schoolid]);


  useEffect(() => {
    setSpeechText(
      `Now lets click into "My Classes" and "Create a New Class" and add students to it.`
    );
  }, [name, schoolid]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);


  useEffect(() => {
    const fetchTrialistDetails = async () => {
      try {
        const response = await getTrialistsBySchool(schoolid); // Fetch data
        if (response.length > 0) {
          const details = response[0]; // Assuming we use the first trial entry
          setTrialistDetails(details);

          // Calculate freeDaysLeft
          const currentDate = new Date();
          const endDate = new Date(details.end_date);
          const timeDiff = endDate - currentDate; // Difference in milliseconds
          const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
          setFreeDaysLeft(daysLeft > 0 ? daysLeft : 0); // Ensure non-negative value
        }
      } catch (error) {
        console.error("Failed to fetch trialist details:", error);
      }
    };
    fetchTrialistDetails();
  }, [schoolid]);

  const handleAssignScreenerClick = () => {
    if (trialistDetails?.expired && !trialistDetails?.purchased) {
      setShowTrialEndedModal(true);
    }
  };

  const handleCloseTrialEndedModal = () => {
    setShowTrialEndedModal(false);
  };


  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={name + "'s" + " " + "Admin"}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
          purchased={purchased}
        />

        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? <div className="spinner-container">
              <div className="big-ball-spinner"></div>
            </div> : (

              <Row className="admindash-row-position">
                <Row>
                  {!purchased ? (
                    <FreeTrialBanner
                      schoolid={schoolid}
                      teacherName={name}
                      freeDaysLeft={freeDaysLeft}
                      trialistDetails={trialistDetails}
                    />
                  ) : (
                    null
                  )}
                </Row>
                <Col className="col-4">
                  <Card className="admindash-card-detail">
                    <Link
                      className="link"
                      to={`/classadmindashboard`}
                      state={{
                        classDetails: { classes },
                        teachers: { teachers },
                        name: { name },
                        rollNumber: { rollNumber },
                        teacherEmail: { teacherEmail },
                      }}
                    >
                      <img
                        src="./assets/imgs/Asset-class.webp"
                        className="admin-class-card-img"
                      ></img>
                      <p className="class-card-text">My Classes</p>
                    </Link>
                  </Card>
                </Col>
                {classes.length !== 0 ? (
                  <>
                    <Col className="col-4">
                      <Card className="admindash-card-detail">



                        {/* <a
                          className="link"
                          href={`https://26983596.hs-sites-eu1.com/other-colleagues/?roll_number_contact=${rollNumber}&email=${teacherEmail}`}
                          target="_blank"
                        >
                        
                          <p className="class-card-text">My Colleagues</p>
                        </a> */}
                        {/* <a
                          className="link"
                          href={`https://26983596.hs-sites-eu1.com/other-colleagues/?roll_number_contact=${rollNumber}&email=${teacherEmail}`}
                          target="_blank"
                        >
                        
                          <p className="class-card-text">My Colleagues</p>
                        </a> */}
                        <Link
                          className="link"
                          to={`/mycolleagues`}
                          state={{
                            classDetails: { classes },
                            teachers: { teachers },
                            name: { name },
                            rollNumber: { rollNumber },
                            teacherEmail: { teacherEmail },
                          }}
                        >
                          <img
                            src="./assets/imgs/mycolleagues-icon.png"
                            className="admin-class-card-img "
                          ></img>
                          <p className="class-card-text">My Colleagues</p>
                        </Link>
                      </Card>
                    </Col>
                    <Col className="col-4">
                      <Card className="admindash-card-detail">
                        <Link
                          className="link"
                          to={`/profile`}
                          state={{
                            classDetails: { classes },
                            teachers: { teachers },
                            name: { name },
                          }}
                        >
                          <img
                            src="./assets/imgs/myinfo-icon.png"
                            className="admin-class-card-img"
                          ></img>
                          <p className="class-card-text">My Info</p>
                        </Link>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <AlpacaInfoGesture speechText={speechText} />
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}
    </div>
  );
}

export default AdminDashboard;