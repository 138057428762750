import { React, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Collapse from "react-bootstrap/Collapse";

import TaskResultsTable2 from "./TaskResultsTable2.js";
import secondsToTime from "../services/secondsToTime";

import "./SelectedResults.css";

function SelectedResults(props) {
  const [open, setOpen] = useState(false);

  let assessment = props.assessment;
  let individualStudentResult = props.individualStudentResult;
  let taskNameArray = props.taskNameArray;
  let student = [props.student];
  let toggleScoreType = props.toggleScoreType;

  let individualStudentsArray = [];
  if (individualStudentResult) {
    individualStudentResult.forEach((result) => {
      individualStudentsArray.push(result);
    });
  }

  function scoreInsertion() {
    let tableRows = document.querySelectorAll(".selected-results-table-row");
    for (let i = 0; i < student.length; i++) {
      let result = student[i];
      let tableRow = tableRows[i];
      let cells = tableRow.querySelectorAll(".student-results-cell");
      let taskScores = result.taskScores;

      taskScores?.forEach((score) => {
        let scoreKeys = Object.keys(score).toString();
        scoreKeys = scoreKeys.replace(/\s+/g, "-").toLowerCase();
        let scoreValues = Object.values(score).toString();

        for (let cell of cells) {
          // console.log(cell)
          let cellValue = cell.getAttribute("data-value");
          if (cellValue === scoreKeys) {
            let cellFirstChild = cell.firstChild;
            if (cellFirstChild) {
              cellFirstChild?.setAttribute("data-score", scoreValues);
              if (toggleScoreType) {
                cellFirstChild.innerHTML = scoreValues;
              } else {
                cellFirstChild.innerHTML = scoreValues + "%";
              }
            }
          }
        }
      });
    }
  }

  function scoreColorImplementation() {
    let taskScores = document.querySelectorAll(".task-score");
    for (let scores of taskScores) {
      // console.log(scores)
      let score = scores.getAttribute("data-score");
      if (score == 0) {
        scores.classList.add("low-color");
      }
      if (score > 0 && score < 25) {
        scores.classList.add("low-color");
      } else if ((score > 25) & (score < 75)) {
        scores.classList.add("med-color");
      } else if ((score > 75) & (score <= 100)) {
        scores.classList.add("high-color");
      }
    }
  }

  useEffect(() => {
    scoreInsertion();
    scoreColorImplementation();
  }, [individualStudentsArray]);

  let assessmentResultsArr = [];
  for (let i = 0; i < individualStudentsArray.length; i++) {
    const assessmentResults = individualStudentsArray[i];
    if (assessment.name === assessmentResults.name) {
      assessmentResultsArr.push(assessmentResults);
    }
  }

  return (
    <Row>
      <Col>
        <div className="">
          <h5>Results - Selected Assessment</h5>
          <Table className="custom-table">
            <thead className="table-header">
              <tr>
                <th className="header-th-left header-vertical-top">
                  Assessment Name
                </th>
                {taskNameArray.map((taskName) => (
                  <th className="header-th header-vertical-top">{taskName}</th>
                ))}
                <th className="header-th header-vertical-top">Time</th>
                <th className="header-th header-vertical-top">View</th>
              </tr>
            </thead>
            <tbody>
              {student.map((results) => (
                <tr className="selected-results-table-row">
                  <td className="centre-item align-left">{assessment.name}</td>
                  {taskNameArray.map((taskName) => (
                    <td
                      className="student-results-cell centre-student-items task-badge-parent"
                      data-value={taskName.replace(/\s+/g, "-").toLowerCase()}
                    >
                      <span className="task-score">Pending</span>
                    </td>
                  ))}
                  <td className="centre-item">{results.time}</td>
                  <td
                    className="centre-item"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >
                    {open === false ? (
                      <img src="../assets/imgs/plus-icon-small.png" />
                    ) : (
                      <img src="../assets/imgs/minus-icon-small.png" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <Accordion className="accordion-margin-zero">
                {assessmentResultsArr.map((result) =>
                  result.tasks.map((task, index) => (
                    <Accordion.Item
                      eventKey={index}
                      style={{ border: "white" }}
                    >
                      <Accordion.Header className="selected-assessment-accordion-head">
                        <Row index={index} className="task-results-table-row">
                          <Col className="col-1"></Col>
                          <Col className="col-9 align-left">{task.name}</Col>
                          <Col>{secondsToTime(task.duration)}</Col>
                        </Row>
                      </Accordion.Header>
                      <Row>
                        <Accordion.Body>
                          <TaskResultsTable2 taskAnswers={task.answers} />
                        </Accordion.Body>
                      </Row>
                    </Accordion.Item>
                  ))
                )}
              </Accordion>
            </div>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
}

export default SelectedResults;
